<template>
  <div class="z-50 flex">
    <div class="settings z-40" :class="{ 'mr-5': sideBar.showAll, 'w-[64px]' : !sideBar.showAll }" style="border: 1px solid #e6e8f0">
      <div class="flex items-center cursor-pointer gap-3">

        <a href="javascript:void(0)" @click="routeUrl()" class="flex items-center">


          <div class=" ml-2.5">
            <svg width="42" height="29" viewBox="0 0 42 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40.4" height="28.8571" transform="translate(0.800781)" fill="white" />
              <rect x="0.800781" width="40.4" height="5.77143" rx="2.88571" fill="#DD5928" />
              <rect x="0.800781" y="11.5428" width="40.4" height="5.77143" rx="2.88571" fill="#DD5928" />
              <rect x="0.800781" y="23.0857" width="40.4" height="5.77143" rx="2.88571" fill="#DD5928" />
            </svg>

          </div>

          <p class="
            text-[27px]
            N700
            font-bold
            sm:hidden
            md:hidden
            lg:block
            xl:block
            pl-3
            hover:text-[#DD5928]
          " v-if="sideBar.showAll">
            AmaliTech
          </p>
        </a>
        <span class="relative h-6 w-6 rounded-full flex items-center py-0 px-2 shadow-md BG0 top-7 "
          @click="sideBar.showAll = !sideBar.showAll" :class="sideBar.showAll ?  '-ml-2' : '-ml-5'">
          <ArrowLeftIcon v-if="sideBar.showAll" />
          <ArrowRightIcon v-else />
        </span>
      </div>
      <div class="">
        <HorizontalLine />
      </div>
      <div class="flex flex-col">
        <div class="flex flex-col">

          <NavigationComponent :item="{ label: 'Client manager', route: '/client-manager', name:'client' }"
            @mouseover="toggleOnClient(true)" @mouseout="toggleOnClient(false)" 
            v-if="Access.permissions.client_manager.view_client_manager">
            <Client :color="(onClient && !sideBar.showAll) ||
      $route.path === '/client-manager' || $route.path?.includes('client')
      ? '#DD5928'
      : '#8F95B2'
      " />
          </NavigationComponent>

          <NavigationComponent :item="{ label: 'Project manager', route: '/project-manager', name:'project' }"
            @mouseover="toggleOnProject(true)" @mouseout="toggleOnProject(false)" 
            v-if="Access.permissions.project_manager.view_project_manager">
            <Project :color="(onProject && !sideBar.showAll) ||
      $route.path === '/project-manager' || $route.path==='/create-project' || $route.name ==='Team' || $route.name ==='Documents' || $route.name ==='Project notes'
      ? '#DD5928'
      : '#8F95B2'
      " />
          </NavigationComponent>

          <NavigationComponent :item="{ label: 'Skills manager', route: '/skill-manager', name:'skills-manager' }"
            @mouseover="toggleOnSkills(true)" @mouseout="toggleOnSkills(false)"
            v-if="Access.permissions.skill_manager.view_skills_manager">
            <Skills :color="(onSkills && !sideBar.showAll) || $route.path === '/skill-manager'
      ? '#DD5928'
      : '#8F95B2'
      " />
          </NavigationComponent>

          <NavigationComponent :item="{ label: 'Staff manager', route: '/staff-manager', name:'staff' }" 
            @mouseover="toggleOnStaff(true)" @mouseout="toggleOnStaff(false)"
            v-if="Access.permissions.staffing_tracker.view_staff_tracker">
            <Staffing :color="(onStaff && !sideBar.showAll) || $route.path === '/staff-manager' || $route.path==='/staff-manager/timeline'
      ? '#DD5928'
      : '#8F95B2'
      " />
          </NavigationComponent>

        </div>

        <div class="mx-2 -mt-2">
          <HorizontalLine />
        </div>
        <div>
          <NavigationComponent
            :item="{ label: 'Settings', route: $route.path.includes('settings') ? $route.path : '/settings/specialisation', name: $route.path === '/create-organizations' ? 'create-organizations' : $route.path === '/create-access' ? 'create-access' : 'setting' }"
            @mouseover="toggleOnSettings(true)" @mouseout="toggleOnSettings(false)" 
            v-if="Access.permissions.settings.manage_settings">

            <Settings :color="(onSettings && !sideBar.showAll) ||
      $route.path?.includes('settings') || $route.path === '/create-organisations' || $route.path === '/create-access'
      ? '#DD5928'
      : '#8F95B2'
      " />
          </NavigationComponent>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { useAccessStore } from "@/apps/authentication/store/store_access";
import Client from "@/apps/resource-manager/assets/ClientManagerIcon.vue";
import Skills from "@/apps/resource-manager/assets/SkillsManagerIcon.vue";
import Project from "@/apps/resource-manager/assets/ProjectManagerIcon.vue";
import Staffing from "@/apps/resource-manager/assets/StaffingIcon.vue";
import Settings from "@/apps/resource-manager/assets/SettingsIcon.vue";
import NavigationComponent from "@/apps/resource-manager/components/NavigationComponent.vue";
import { ref } from "vue";
import HorizontalLine from "./../ui-kit/HorizontalLine.vue";
import { useSideBar, useStore } from "@/store";
import ArrowLeftIcon from '@/assets/ArrowLeftIcon.vue';
import ArrowRightIcon from '@/assets/ArrowRightIcon.vue';
import { getToken } from "@/services/auth";
import config from '@/config/config';

const store = useStore()
const loginHint: string = store.loginHint;
const routeUrl = () => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${config.mainPageLink}?app-token=${token}&login-hint=${loginHint}`;

  }
}

const emits = defineEmits(["appSelected"]);
// user permissions
const Access = useAccessStore();
const sideBar = useSideBar();



const onClient = ref(false);
const onProject = ref(false);
const onSkills = ref(false);
const onStaff = ref(false);
const onSettings = ref(false);



const toggleOnClient = (status: boolean) => {
  onClient.value = status;
};

const toggleOnProject = (status: boolean) => {
  onProject.value = status;
};

const toggleOnSkills = (status: boolean) => {
  onSkills.value = status;
};

const toggleOnStaff = (status: boolean) => {
  onStaff.value = status;
};

const toggleOnSettings = (status: boolean) => {
  onSettings.value = status;
};


</script>

<style scoped>
.settings {
  height: 100vh;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding: 20px 0px 0 0px;
  margin-right: 24px;
  color: #474d66;
  background-color: white;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.settings::-webkit-scrollbar {
  display: none;
}


.H700 {
  z-index: 2;
}

@media screen and (min-width: 670px) and (max-width: 768px) {
  .menu {
    padding: 10px;
  }
}

@media screen and (max-width: 670px) {
  .menu {
    position: relative;
    top: 0;
    height: 40px;
    left: 5px;
    border: 1px solid #d8dae5;
    border-radius: 8px;
  }

  .menu_view {
    width: auto;
  }
}


.setting {
  height: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding: 95px 10px 0 10px;
  margin-left: 80px;
  color: #474d66;
  background-color: white;
  z-index: 11;
  position: fixed;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>